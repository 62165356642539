/* jshint ignore:start */
var wW;
var wH;
var transitionPageDelay = 800 + 50;

var scrollTop;
var footer = jQuery('.footer');
var footerHeight;

var borderWidth;
var touch = typeof window.ontouchstart !== 'undefined';
var is_iPad = navigator.userAgent.match(/iPad/i) != null;
var $browser;


jQuery(document).ready(function ($) {

    initHeaderFooter = function () {
        console.log("2 - Dentro InitHeaderFooter");

        var loaderTL = new TimelineMax();
        loaderTL.set(".ondepath", {drawSVG: "0%"})
            .to(".ondepath", 0.8, {drawSVG: "0% 70%", ease: Power3.easeIn})
            .to(".ondepath", 0.1, {drawSVG: "10% 80%", ease: Power3.easeNone})
            .to(".ondepath", 0.1, {drawSVG: "20% 90%", ease: Power3.easeNone})
            .to(".ondepath", 0.1, {drawSVG: "30% 100%", ease: Power3.easeOut})
            .to(".ondepath", 0.8, {drawSVG: "100% 100%", ease: Power3.easeOut});

        /* Menù per cellulari */
        $('.navbar-toggle').on('click', function (e) {
            e.preventDefault();
            $('body').toggleClass('menuOpen');

            if (!$('body').hasClass('menuOpen')) {
                $(".navbar-toggle").trigger('mouseleave');
                if (is_iPad) {
                    $('html').css({overflow: 'auto', height: 'auto'});
                }
            } else {
                if (is_iPad) {
                    $('html').css({overflow: 'hidden', height: '100%'});
                }
            }
        })
        $(".navbar-toggle").hover(function (e) {
            $('body').toggleClass('menuHover', e.type === 'mouseenter');
        });

        /* Funzione per il "torna su" */
        $('.scrolltop').on('click', function (e) {
            e.preventDefault();
            TweenLite.to(window, 0.8, {scrollTo: 0, ease: Power3.easeInOut});
        });

        TweenLite.ticker.addEventListener("tick", onScroll);

        function onScroll() {
            scrollTop = $(window).scrollTop();

            if (scrollTop > wH && !$('.scrolltop').hasClass('showed')) {
                $('.scrolltop').addClass('visible');
            } else {
                $('.scrolltop').removeClass('visible');
            }

            if (scrollTop > $('.page-container').outerHeight() - window.innerHeight + borderWidth) {

                if (wW > 768) {
                    TweenLite.to($('footer .contactLink'), 0, {
                        y: -footerHeight * 1 + ((scrollTop - ($('.page-container').outerHeight() - window.innerHeight)) * 1),
                        ease: Linear.easeNone
                    });
                    TweenLite.to($('footer .contact'), 0, {
                        y: -footerHeight * 0.5 + ((scrollTop - ($('.page-container').outerHeight() - window.innerHeight)) * 0.5),
                        ease: Linear.easeNone
                    });
                    TweenLite.to($('footer .credits'), 0, {
                        y: -footerHeight * 0.1 + ((scrollTop - ($('.page-container').outerHeight() - window.innerHeight)) * 0.1),
                        ease: Linear.easeNone
                    });
                }
                TweenLite.to($('.scrolltop'), 0, {
                    y: -($('body').scrollTop() - ($('.page-container').outerHeight() - window.innerHeight + borderWidth)),
                    ease: Linear.easeNone
                });
                TweenLite.to($('.scrolldown'), 0, {
                    y: -($('body').scrollTop() - ($('.page-container').outerHeight() - window.innerHeight + borderWidth)),
                    ease: Linear.easeNone
                });
                TweenLite.to($('.scrolldown'), 0, {css: {opacity: 1 - (($('body').scrollTop() - ($('.page-container').outerHeight() - window.innerHeight + borderWidth)) * 0.01)}});
            } else {
                if (wW > 768) {
                    TweenLite.to($('footer .briefLink'), 0, {y: -footerHeight * 1, ease: Linear.easeNone});
                    TweenLite.to($('footer .contact'), 0, {y: -footerHeight * 0.5, ease: Linear.easeNone});
                    TweenLite.to($('footer .credits'), 0, {y: -footerHeight * 0.1, ease: Linear.easeNone});
                }
                TweenLite.to($('.scrolltop'), 0, {y: 0, ease: Linear.easeNone});
                TweenLite.to($('.scrolldown'), 0, {y: 0, opacity: 1, ease: Linear.easeNone});
            }
        }
    };

    initHome = function () {
        var tlHi;
        var tlWho;
        var tlWebDevelopment;
        var tlWebMark;
        var tlStartUp;
        var controller = new ScrollMagic.Controller();

        var typed = new Typed('#typed', {
            stringsElement: '#typed-strings',
            showCursor: false,
            backDelay: 2500,
            loop: true,
            typeSpeed: 1,
        });

        ///ANIMATION: HI
        tlHi = new TimelineLite();
        tlHi.staggerFrom($("#Hi_h > *"), 0.6, {drawSVG: "0%", ease: Power3.easeOut}, 0.2, 0.8)
            .staggerFrom($("#Hi_i > *"), 0.6, {drawSVG: "0%", ease: Power3.easeOut}, 0.1, "-=0.6")
            .staggerFrom($("#Hi_i2 > *"), 0.6, {drawSVG: "0%", ease: Power3.easeOut}, 0.1, "-=0.1")
            .staggerFrom($("#Hi_dot > *"), 0.6, {
                scale: 0, transformOrigin: "50% 50%", ease: Power3.easeOut
            }, 0.2, "-=0.1")
            .from($(".hello-scrolldown"), 1.2, {bottom: -50, ease: Power3.easeOut}, "-=0.8");

        /*if ($browser.name == "Safari" && $browser.version < 10) {
            tlHi.progress(1, false); // credo salti completamente l'animazione
        } else {
            tlHi.play().timeScale(1);
        }*/

        // ANIMATION: WHO
        tlWho = new TimelineLite({paused: true});
        var whoText = new SplitText("#quote", {type: "lines"}),
            whoLines = whoText.lines;

        tlWho.from(".who .block-title .waves", 0.8, {x: "-20%", opacity: 0, ease: Power3.easeOut}, "-=0.60")
            .from(".who .block-title .mskd", 0.8, {x: "-20%", opacity: 0, ease: Power3.easeOut}, "-=0.60")
            .from(".type-wrap", 0.8, {y: "100%", opacity: 0, ease: Power3.easeInOut}, "-=0.60")
            .staggerFrom(whoLines, 1.2, {y: "100%", opacity: 0, ease: Power3.easeInOut}, 0.08, "-=1");

        var scene1 = new ScrollMagic.Scene({
            triggerElement: "#intro",
            triggerHook: 0,
            duration: '50',
        }).setClassToggle('body', 'showHello')
            .addTo(controller);

        scene1.on("end", function () {
            tlWho.play().timeScale(1);
        });
        scene1.on("progress", function () {
            tlWho.reverse().timeScale(2);
        });

        // ANIMATION: MOBILE
        var e, n, i, r, s, o, l;
        l = $(".services-types-mobile .item").first().innerHeight();
        e = 377, o = -164, n = (l - e) / 2 - o;

        var scene3 = new ScrollMagic.Scene({
            triggerElement: ".services-types-mobile .item.is-business",
            duration: l,
            triggerHook: 1
        }).setTween(TweenMax.fromTo(".services-types-mobile .item.is-business .back", 1,
            {transform: "translateY(" + .08 * l + "px)"},
            {transform: "translateY(" + n + "px)", ease: Sine.easeInOut}))
            .addTo(controller);

        var scene4 = new ScrollMagic.Scene({
            triggerElement: ".services-types-mobile .item.is-consumer",
            triggerHook: 1
        }).setPin(".services-types-mobile .item.is-business .pin-wrapper", {pushFollowers: !1})
            .addTo(controller);

        i = 696, s = -126, r = (l - i) / 2 - s;

        var scene5 = new ScrollMagic.Scene({
            triggerElement: ".services-types-mobile .item.is-business",
            duration: l,
            triggerHook: 1
        }).setTween(TweenMax.fromTo(".services-types-mobile .item.is-business .middle", 1,
            {transform: "translateY(" + l * -.05 + "px)"},
            {transform: "translateY(" + r + "px)", ease: Sine.easeInOut}))
            .addTo(controller);

        var scene6 = new ScrollMagic.Scene({
            triggerElement: ".services-types-mobile .item.is-business",
            duration: l,
            triggerHook: 1
        }).setTween(TweenMax.fromTo(".services-types-mobile .item.is-business .front-bottom", 1, {transform: "translateY(-180px)"}, {
            transform: "translateY(-10px)",
            ease: Sine.easeInOut
        })).addTo(controller);

        var scene7 = new ScrollMagic.Scene({
            triggerElement: ".services-types-mobile .item.is-business",
            duration: l,
            triggerHook: 1
        }).setTween(TweenMax.fromTo(".services-types-mobile .item.is-business .front-top", 1, {transform: "translateY(" + l * -.25 + "px)"}, {
            transform: "translateY(" + l * -.05 + "px)", ease: Sine.easeInOut
        })).addTo(controller);

        var scene8 = new ScrollMagic.Scene({
            triggerElement: ".services-types-mobile .item.is-business",
            duration: l,
            triggerHook: 1
        }).setTween(TweenMax.fromTo(".services-types-mobile .item.is-business .content-wrapper", .1,
            {opacity: -1}, {opacity: 1, ease: Sine.easeInOut}))
            .addTo(controller);

        var scene9 = new ScrollMagic.Scene({
            triggerElement: ".services-types-mobile .item.is-consumer",
            duration: .5 * l,
            triggerHook: 1
        }).setTween(TweenMax.fromTo(".services-types-mobile .item.is-business .content-wrapper", .1, {opacity: 1}, {
            opacity: -1,
            ease: Expo.easeIn
        }))
            .addTo(controller);

        var scne10 = new ScrollMagic.Scene({
            triggerElement: ".services-types-mobile .item.is-consumer",
            duration: 2 * wH,
            triggerHook: 1
        }).setTween(TweenMax.fromTo(".services-types-mobile .item.is-consumer .back", 1, {transform: "translateY(" + l * -.45 + "px)"}, {
            transform: "translateY(" + 1.25 * l + "px)",
            ease: Linear.easeNone
        })).addTo(controller);

        var scene11 = new ScrollMagic.Scene({
            triggerElement: ".services-types-mobile .item.is-consumer",
            duration: 2 * wH,
            triggerHook: 1
        }).setTween(TweenMax.fromTo(".services-types-mobile .item.is-consumer .middle", 1, {transform: "translateY(" + l * -.52 + "px)"}, {
            transform: "translateY(" + .98 * l + "px)",
            ease: Linear.easeNone
        })).addTo(controller);

        var scene12 = new ScrollMagic.Scene({
            triggerElement: ".services-types-mobile .item.is-consumer",
            duration: 2 * wH,
            triggerHook: 1
        }).setTween(TweenMax.fromTo(".services-types-mobile .item.is-consumer .front", 1, {transform: "translateY(" + l * -.4 + "px)"}, {
            transform: "translateY(" + .7 * l + "px)",
            ease: Linear.easeNone
        })).addTo(controller);

        var scene13 = new ScrollMagic.Scene({
            triggerElement: ".services-types-mobile .item.is-consumer",
            triggerHook: 1
        }).setPin(".services-types-mobile .item.is-consumer .pin-wrapper", {pushFollowers: !1})
            .addTo(controller);

        var scene14 = new ScrollMagic.Scene({
            triggerElement: ".services-types-mobile .item.is-consumer",
            duration: .7 * l,
            triggerHook: 1
        }).setTween(TweenMax.fromTo(".services-types-mobile .item.is-consumer .content-wrapper", .1, {opacity: -1}, {
            opacity: 1,
            ease: Expo.easeIn
        })).addTo(controller);

        var scene15 = new ScrollMagic.Scene({
            triggerElement: ".services-types-mobile .item.is-entertainment",
            duration: .5 * l,
            triggerHook: 1
        }).setTween(TweenMax.fromTo(".services-types-mobile .item.is-consumer .content-wrapper", .1, {opacity: 1}, {
            opacity: 0,
            ease: Expo.easeIn
        })).addTo(controller);

        var scene16 = new ScrollMagic.Scene({
            triggerElement: ".services-types-mobile .item.is-entertainment",
            duration: 2 * wH,
            triggerHook: 1
        }).setTween(TweenMax.fromTo(".services-types-mobile .item.is-entertainment .back", 1, {transform: "translateY(" + l * -.33 + "px)"}, {
            transform: "translateY(" + .67 * l + "px)",
            ease: Linear.easeNone
        })).addTo(controller);

        var scene17 = new ScrollMagic.Scene({
            triggerElement: ".services-types-mobile .item.is-entertainment",
            duration: 2 * wH,
            triggerHook: 1
        }).setTween(TweenMax.fromTo(".services-types-mobile .item.is-entertainment .middle", 1, {transform: "translateY(" + .03 * l + "px)"}, {
            transform: "translateY(" + .45 * l + "px)",
            ease: Linear.easeNone
        })).addTo(controller);

        var scene18 = new ScrollMagic.Scene({
            triggerElement: ".services-types-mobile .item.is-entertainment",
            duration: l,
            triggerHook: 1
        }).setPin(".services-types-mobile .item.is-entertainment .pin-wrapper", {pushFollowers: !1})
            .addTo(controller);

        var scene19 = new ScrollMagic.Scene({
            triggerElement: ".services-types-mobile .item.is-entertainment",
            duration: 2 * wH,
            triggerHook: 1
        }).setTween(TweenMax.fromTo(".services-types-mobile .item.is-entertainment .front", 1, {transform: "translateY(" + .8 * l + "px)"}, {
            transform: "translateY(" + .35 * l + "px)",
            ease: Linear.easeNone
        })).addTo(controller);

        var scene20 = new ScrollMagic.Scene({
            triggerElement: ".services-types-mobile .item.is-entertainment",
            duration: .75 * l,
            triggerHook: 1
        }).setTween(TweenMax.fromTo(".services-types-mobile .item.is-entertainment .content-wrapper", .1, {opacity: -1}, {
            opacity: 1,
            ease: Expo.easeIn
        })).addTo(controller);

        tlWebDevelopment = new TimelineLite();
        var webDevTitle = new SplitText(".web-development h2 .txt", {type: "lines"}),
            webDevTitleLines = webDevTitle.lines;
        var webDevIntro = new SplitText(".web-development .block-content .intro", {type: "lines"}),
            webDevIntroLines = webDevIntro.lines;

        tlWebDevelopment.from(".web-development .block-title .waves", 0.8, {
            x: "-20%",
            opacity: 0,
            ease: Power3.easeOut
        }, "-=0.60")
            .from(".web-development .block-title .mskd", 0.8, {x: "-20%", opacity: 0, ease: Power3.easeOut}, "-=0.60")
            .staggerFrom(webDevTitleLines, 1.2, {
                y: "100%",
                opacity: 0,
                ease: Power3.easeInOut
            }, 0.25, "-=0.5")
            .staggerFrom(webDevIntroLines, 0.8, {y: "100%", opacity: 0, ease: Power3.easeInOut}, 0.25, "-=1")
            .from(".web-development .block-content p", 0.8, {y: 60, opacity: 0, ease: Power3.easeOut}, "-=0.5");

        var hx = 400;
        if (wW >= 992) {
            hx = $(".web-development").innerHeight() - 350;
        }

        console.log('Duration web development: '+ hx +', wW:' +wW);

        var sceneWebDevelopment = new ScrollMagic.Scene({
            triggerElement: ".web-development",
            triggerHook: 0.8,
            duration: hx,
        }).setTween(tlWebDevelopment)
            .addTo(controller);

        var sceneSkills = new ScrollMagic.Scene({
            triggerElement: ".skills-container",
            triggerHook: 1,
            offset: '100',
        }).setClassToggle('.skills-container', 'visible')
            .addTo(controller);

        tlWebMark = new TimelineLite();
        var webMarkTitle = new SplitText(".web-marketing h2 .txt", {type: "lines"}),
            webMarkTitleLines = webMarkTitle.lines;
        var webMarkIntro = new SplitText(".web-marketing .block-content .intro", {type: "lines"}),
            webMarkIntroLines = webMarkIntro.lines;

        tlWebMark.from(".web-marketing .block-title .waves", 0.8, {
            x: "-20%",
            opacity: 0,
            ease: Power3.easeOut
        }, "-=0.60")
            .from(".web-marketing .block-title .mskd", 0.8, {x: "-20%", opacity: 0, ease: Power3.easeOut}, "-=0.60")
            .staggerFrom(webMarkTitleLines, 1.2, {y: "100%", opacity: 0, ease: Power3.easeInOut}, 0.25, "+=0.5")
            .staggerFrom(webMarkIntroLines, 0.8, {y: "100%", opacity: 0, ease: Power3.easeInOut}, 0.25, "-=0.5")
            .from(".web-marketing .block-content p", 0.8, {y: 60, opacity: 0, ease: Power3.easeOut}, "-=0.5")
            .from(".web-marketing .img-container", 1.2, {x: "-20%", opacity: 0, ease: Power3.easeOut}, "-=0.60")
            .staggerFrom(".web-marketing .timeline li", 1.8, {
                y: "100%",
                opacity: 0,
                ease: Power3.easeInOut
            }, 0.25, "-=0.80");

        var hx2 = 500;
        if (wW >= 992) {
            hx2 = $(".web-marketing").innerHeight() - 250;
            console.log('992: Duration web marketing: '+ hx2 +', wW:' +wW);
        } else if (768 <= wW && wW < 992) {
            hx2 = $(".web-marketing").innerHeight() / 2;
            console.log('768-992: Duration web marketing: '+ hx2 +', wW:' +wW);
        }

        console.log('Duration web marketing: '+ hx2 +', wW:' +wW);
        var sceneWebMark = new ScrollMagic.Scene({
            triggerElement: ".web-marketing",
            duration: hx2,
            triggerHook: 0.7,
        }).setTween(tlWebMark)
            .addTo(controller);

        var hx3 = $(".ideas").outerHeight();
        var sceneStartUpImg = new ScrollMagic.Scene({
            triggerElement: ".ideas .visuel-container",
            triggerHook: 1,
            offset: '150',
        }).setClassToggle('.ideas .visuel-container', 'visible')
            .addTo(controller);

        tlStartUp = new TimelineLite();
        var startupTitleText = new SplitText(".ideas h2 .txt", {type: "lines"}),
            startupTitleLines = startupTitleText.lines;

        var startupIntroText = new SplitText(".ideas .block-content p.intro", {type: "lines"}),
            startupIntroLines = startupIntroText.lines;

        tlStartUp.from(".ideas .block-title .waves", 0.8, {x: "-20%", opacity: 0, ease: Power3.easeOut}, "-=0.60")
            .from(".ideas .block-title .mskd", 0.8, {x: "-20%", opacity: 0, ease: Power3.easeOut}, "-=0.60")
            .staggerFrom(startupTitleLines, 1.4, {y: "100%", opacity: 0, ease: Power3.easeInOut}, 0.25, "-=0.7")
            .staggerFrom(startupIntroLines, 1.2, {y: "100%", opacity: 0, ease: Power3.easeInOut}, 0.25, "-=0.5")
            .staggerFrom(".ideas .block-content p.intro ~ p", 0.8, {
                y: 60,
                opacity: 0,
                ease: Power3.easeOut
            }, 0.08, "-=1")
            .from(".ideas .block-content a", 0.8, {y: 60, opacity: 0, ease: Power3.easeOut}, "-=1");

        tlStartUp.from(".ideas .visuel-container img", 3, {yPercent: "-50", ease: Linear.easeNone}, 0);

        var sceneStartUp = new ScrollMagic.Scene({
            triggerElement: ".ideas",
            triggerHook: 0.7,
            duration: hx3,
        }).setTween(tlStartUp)
            .addTo(controller);

        TweenLite.ticker.addEventListener("tick", onScroll);

        function onScroll() {
            //console.log('Dentro onScroll di initHome()');
            var scrollAmount = $(window).scrollTop();
            var scrollWho = $('.who').outerHeight() / 2;

            //PRLLX WHO
            if (scrollAmount > scrollWho && scrollAmount < $('.who').outerHeight() + wH) {
                TweenLite.to($('.hello'), 0, {
                    y: -(scrollAmount - scrollWho) * 0.5,
                    ease: Linear.easeNone
                });
                TweenLite.to($('.who'), 0, {
                    y: -(scrollAmount - scrollWho) * 0.5,
                    ease: Linear.easeNone
                });
            }

        }

    }


    build = function () {
        TweenLite.set(window, {scrollTo: {y: 0, autoKill: false}, delay: 0.1});

        $browser = checkNavigateur();

        // Library for eliminating the 300ms delay between a physical tap and the firing of a click event on mobile browsers
        //FastClick.attach(document.body);

        $(window).on('resize', function () {
            console.log('dentro onResize di build');
            wW = $(window).width();
            wH = $(window).height();
            footerHeight = footer.outerHeight();
            $('.page-container').css({marginBottom: footerHeight});
            calculBorderSize();
        });
        $(window).trigger('resize');

        /* Setta l'header ed il footer */
        initHeaderFooter();

        var targetPageClass = $('main').data('class');

        $('body').addClass(targetPageClass).delay(800).queue(function () {

            TweenLite.set(window, {scrollTo: {y: 0, autoKill: false}, delay: 0.1});
            $(window).trigger('resize');

            if (targetPageClass == 'page-home') {
                initHome();
            }
            $('body').removeClass('loading').delay(800).queue(function () {
                $(this).addClass('loaded');
                $(this).dequeue();
            }).dequeue();

        });
    }

    build();

    function normalize(value, min, max) {
        return (value - min) / (max - min);
    }

    function clamp(value, min, max) {
        return value < min ? min : (value > max ? max : value);
    }

    function calculBorderSize() {
        switch (true) {
            case (wW >= 1600) :
                borderWidth = 60;
                break;
            case (992 <= wW < 1600) :
                borderWidth = 40;
                break;
                borderWidth = 20;
        }
    }

    /* Restituisce in nome del browser insieme alla sua versione. */
    function checkNavigateur() {

        var nVer = navigator.appVersion;
        var nAgt = navigator.userAgent;
        var browserName = navigator.appName;
        var fullVersion = '' + parseFloat(navigator.appVersion);
        var majorVersion = parseInt(navigator.appVersion, 10);
        var nameOffset, verOffset, ix;

        // In Opera, the true version is after "Opera" or after "Version"
        if ((verOffset = nAgt.indexOf("Opera")) != -1) {
            browserName = "Opera";
            fullVersion = nAgt.substring(verOffset + 6);
            if ((verOffset = nAgt.indexOf("Version")) != -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
        // In MSIE, the true version is after "MSIE" in userAgent
        else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
            browserName = "Microsoft Internet Explorer";
            fullVersion = nAgt.substring(verOffset + 5);
        }
        // In Chrome, the true version is after "Chrome"
        else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
            browserName = "Chrome";
            fullVersion = nAgt.substring(verOffset + 7);
        }
        // In Safari, the true version is after "Safari" or after "Version"
        else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
            browserName = "Safari";
            fullVersion = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf("Version")) != -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
        // In Firefox, the true version is after "Firefox"
        else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
            browserName = "Firefox";
            fullVersion = nAgt.substring(verOffset + 8);
        }
        // In most other browsers, "name/version" is at the end of userAgent
        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
            (verOffset = nAgt.lastIndexOf('/'))) {
            browserName = nAgt.substring(nameOffset, verOffset);
            fullVersion = nAgt.substring(verOffset + 1);
            if (browserName.toLowerCase() == browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }
        // trim the fullVersion string at semicolon/space if present
        if ((ix = fullVersion.indexOf(";")) != -1)
            fullVersion = fullVersion.substring(0, ix);
        if ((ix = fullVersion.indexOf(" ")) != -1)
            fullVersion = fullVersion.substring(0, ix);

        majorVersion = parseInt('' + fullVersion, 10);

        if (isNaN(majorVersion)) {
            fullVersion = '' + parseFloat(navigator.appVersion);
            majorVersion = parseInt(navigator.appVersion, 10);
        }

        return {name: browserName, version: majorVersion};

    }

});